'use client'
import { Container } from '@mui/material'
import { AppLoading } from 'ui'

export default function Loading() {
  return (
    <Container maxWidth={false}>
      <AppLoading boxProps={{ height: '100dvh' }} />
    </Container>
  )
}
